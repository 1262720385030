export const SEND_NOTIFICATION_URL = process.env.REACT_APP_API_URL + "bvi/send/notification";
// export const ADD_VACINATION_URL = process.env.REACT_APP_API_URL + "bvi/add/vaccination/details";
export const VAX_DETAILS_UPDATE =
  process.env.REACT_APP_API_URL + "bvi/vax/details/submit";
export const GET_VACCINATION_URL =
  process.env.REACT_APP_API_URL + "bvi/get/vaccination/configs";
export const GET_COUNTIES_URL =
  process.env.REACT_APP_API_URL + "public/get/state/counties";
export const GET_ORG_URL =
  process.env.REACT_APP_API_URL + "public/get/organizations";
export const GET_BVI_LABS = process.env.REACT_APP_API_URL + "bvi/get/labs";
export const GET_BVI_COUNTRYLIST =
  process.env.REACT_APP_API_URL + "bvi/country/list";
export const GET_BVI_STATELIST =
  process.env.REACT_APP_API_URL + "bvi/state/list";
export const GET_BVI_CITYLIST = process.env.REACT_APP_API_URL + "bvi/city/list";
export const GET_OTP_URL = process.env.REACT_APP_API_URL + "public/get/otp/to";
export const SENDOTP_URL = process.env.REACT_APP_API_URL + "public/send/otp";
export const VALIDATEOTP_URL =
  process.env.REACT_APP_API_URL + "public/validate/otp";
  export const MISSING_DOSE_URL = process.env.REACT_APP_API_URL + 'bvi/vax/add/missing/dose';
export const REUPLOAD_NEW = process.env.REACT_APP_API_URL + "bvi/fileUpload/new";
export const REUPLOAD_EXIST = process.env.REACT_APP_API_URL + "bvi/fileUpload/exist";
export const REUPLOAD_MISSING_DOSE = process.env.REACT_APP_API_URL + "bvi/fileUpload/missingDose";