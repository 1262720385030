import React from "react";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { useHistory } from "react-router-dom";
import "./Viewer.css";
import Applewallet from "../../Assets/Apple_Wallet.svg";
import Googlewallet from "../../Assets/GooglePay.svg";

export default function PDFViewer(props) {
  const history = useHistory();
  const Back = () => {
    history.push({
      pathname: "/retrievalcode",
    });
    localStorage.clear();
  };
  const walletOpen = () => {
    history.push({
      pathname: "/wallet-card",
    });
  };

  const redirect_missing = () => {
    history.push({
      pathname: "/missing-dose",
    });
  };

  return (
    <>
      <div className="wallet_container">
        <div style={{ marginTop: "25px" }}>
          <button className="missingDoseBtn missingDoseBtn1" onClick={redirect_missing}>
            Report a missing dose
          </button>
        </div>
        <div>
          <div className="users">Paper wallet</div>
          <button className="walletbtn walltetbtn1" onClick={walletOpen}>
            Wallet Vax Card
          </button>
        </div>
        <div>
          <div style={{ marginBottom: "4px" }} className="users">
            For iOS users
          </div>
          <a href={localStorage.getItem("applestate")} target="_blank">
            <img src={Applewallet} className="appleimg" />
          </a>
        </div>
        <div>
          <div className="users">For Android users</div>

          <a href={localStorage.getItem("googlestate")} target="_blank">
            <img src={Googlewallet} className="googleimg" />
          </a>
        </div>
      </div>

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js">
        <div className="pdfbody">
          <Viewer defaultScale={1.5} fileUrl={localStorage.getItem("pdf")} />
        </div>
      </Worker>

      <div className="btnbox">
        <button className="pdfbtn pdfbtn1" onClick={Back}>
          Back
        </button>
      </div>
    </>
  );
}
