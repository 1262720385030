import React, { useState, useEffect } from "react";
import "./Form.css";
import Stepper from "react-stepper-horizontal";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import * as API from "../../Service/API/index.js";
import CircularProgress from "@mui/material/CircularProgress";
import { Validate } from "./Validation.js";
import FirstStepper from "./FirstStepper";
import SecondStepper from "./SecondStepper";
import ThirdStepper from "./ThirdStepper";
import FourthStepper from "./FourthStepper";

const MainForm = () => {
  useEffect(() => {
    let year_array = [];
    for (var i = moment(new Date()).format("yyyy"); i - 1909; i--) {
      year_array.push(i);
    }
    SetYear(year_array);

    let date_array = [];
    for (var j = 1; j <= 31; j++) {
      let number = j < 10 ? "0" + j : j;
      date_array.push(number);
    }
    SetDate(date_array);
  }, []);

  //======Stepper States======//
  const [currentStep, setcurrentStep] = useState(0);
  const [steps, Setsteps] = useState([
    {
      title: "Get Started",
    },
    {
      title: "Verify Identity",
    },
    {
      title: "Submit Request",
    },
  ]);

  //======Show DIV States======//
  const [showdiv1, setshowdiv1] = useState(true);
  const [showdiv2, setshowdiv2] = useState(false);
  const [showdiv3, setshowdiv3] = useState(false);
  const [showdiv4, setshowdiv4] = useState(false);

  //======Date of birth States======//
  const [month] = useState([
    { id: "01", month: "January" },
    { id: "02", month: "February" },
    { id: "03", month: "March" },
    { id: "04", month: "April" },
    { id: "05", month: "May" },
    { id: "06", month: "June" },
    { id: "07", month: "July" },
    { id: "08", month: "August" },
    { id: "09", month: "September" },
    { id: "10", month: "October" },
    { id: "11", month: "November" },
    { id: "12", month: "December" },
  ]);
  const [year, SetYear] = useState([]);
  const [date, SetDate] = useState([]);

  //======First Stepper States======//
  const [loader, setloader] = React.useState(false);
  const [newnumber, setNumber] = React.useState("");
  const [dialCode, setdialCode] = React.useState("");
  const [receiveSMS, setreceiveSMS] = React.useState(true);

  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    dobmonth: "",
    dobdate: "",
    dobyear: "",
  });

  const [drivingFile, setdrivingFile] = React.useState();
  const [drivingFilesize, setdrivingFilesize] = React.useState(false);
  const [drivingTypeError, setdrivingTypeError] = React.useState(false);
  const [drivingimgShow, setdrivingimgshow] = React.useState("");
  const [drivingError, setdrivingError] = React.useState(false);
  const [ApiError, SetApiError] = React.useState(false);

  //======Error States======//
  const [errorState, setErrorstate] = useState({
    isError: false,
    FieldName: "",
    Message: "",
  });

  //=========================================================//

  //======On Change Functionalities======//
  const onChange = (e) => {

    if (e.target.name == "firstName" || e.target.name == "middleName" || e.target.name == "lastName" || e.target.name == "email") {
      let value = e.target.value.replaceAll('"', "'");
      setDetails({ ...details, [e.target.name]: value.replaceAll("''", "'") });
    } else {
      setDetails({ ...details, [e.target.name]: e.target.value });
    }

  };

  const mobileNumberChange = (number, e) => {
    setdialCode(e.dialCode);
    setNumber(number);
  };

  //======First Stepper Validation & API Call======//
  const next1 = () => {
    let firstFormDetails = {
      ...details,
      newnumber: newnumber,
      dialCode: dialCode,
    };
    const value = Validate(firstFormDetails);
    if (value) {
      setErrorstate({
        isError: value.isError,
        FieldName: value.FieldName,
        Message: value.Message,
      });
    } else {
      setErrorstate({
        isError: false,
        FieldName: "",
        Message: "",
      });
      // setloader(true);
      let data = {
        firstName: details.firstName.trim(),
        lastName: details.lastName.trim(),
        dob: `${details.dobyear}-${details.dobmonth}-${details.dobdate}`,
      };
      // setloader(false);
      setshowdiv1(false);
      setshowdiv2(true);
      setcurrentStep(1);
    }
  };

  const EnterKeynext = (evt) => {
    if (evt.keyCode == 13) {
      next1();
    }
  };

  const Backbtn = () => {
    Setsteps([
      {
        title: "Get Started",
      },
      {
        title: "Verify Identity",
      },
      {
        title: "Submit Request",
      },
    ]);
    localStorage.clear();
    setcurrentStep(0);
    setshowdiv1(true);
    setshowdiv2(false);
    setshowdiv3(false);

    setDetails({
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      dobmonth: "",
      dobdate: "",
      dobyear: "",
    });

    setNumber("");
    setdialCode("");


  }

  return (
    <div className="form_container">
      <div className="stepper">
        <Stepper
          size={41}
          circleFontSize={15}
          steps={steps}
          activeStep={currentStep}
          activeColor="#4bd3c5"
          completeColor="#4bd3c5"
        />
      </div>
      {showdiv1 ? (
        <>
          {loader === true ? (
            <div className="loaderAdj">
              <CircularProgress />
            </div>
          ) : (
            <FirstStepper
              details={details}
              newnumber={newnumber}
              mobileNumberChange={mobileNumberChange}
              receiveSMS={receiveSMS}
              setreceiveSMS={setreceiveSMS}
              year={year}
              date={date}
              month={month}
              errorState={errorState}
              onChange={onChange}
              EnterKeynext={EnterKeynext}
              next1={next1}
            />
          )}
        </>
      ) : showdiv2 ? (
        <>
          {loader === true ? (
            <div className="loaderAdj">
              <CircularProgress />
            </div>
          ) : (
            <SecondStepper
              setloader={setloader}
              drivingFile={drivingFile}
              drivingFilesize={drivingFilesize}
              drivingTypeError={drivingTypeError}
              drivingimgShow={drivingimgShow}
              drivingError={drivingError}
              ApiError={ApiError}
              setdrivingFile={setdrivingFile}
              setdrivingFilesize={setdrivingFilesize}
              setdrivingTypeError={setdrivingTypeError}
              setdrivingimgshow={setdrivingimgshow}
              setdrivingError={setdrivingError}
              SetApiError={SetApiError}
              setshowdiv2={setshowdiv2}
              setshowdiv3={setshowdiv3}
              setshowdiv4={setshowdiv4}
              setcurrentStep={setcurrentStep}
            />
          )}
        </>
      ) : showdiv3 ? (
        <>
          {loader === true ? (
            <div className="loaderAdj">
              <CircularProgress />
            </div>
          ) : (
            <ThirdStepper
              details={details}
              newnumber={newnumber}
              setNumber={setNumber}
              dialCode={dialCode}
              receiveSMS={receiveSMS}
              setloader={setloader}
              setshowdiv2={setshowdiv2}
              setshowdiv3={setshowdiv3}
              setshowdiv4={setshowdiv4}
              setcurrentStep={setcurrentStep}
              drivingFile={drivingFile}
              setdrivingFile={setdrivingFile}
            />
          )}
        </>
      ) : showdiv4 ? (
        <>
          {loader === true ? (
            <div className="loaderAdj">
              <CircularProgress />
            </div>
          ) : (
            <FourthStepper
              Backbtn={Backbtn}
            />
          )}
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default MainForm;
