import './App.css';
import Footer from "./Components/Footer/Footer";
import MainForm from "./Components/Form/MainForm.js";
import Header from "./Components/Header/Header";
import Login from "./Components/Auth/Login";
import LoginOTP from "./Components/Auth/LoginOTP";
import MissingDose from "./Components/Form/MissingDose";
import RetrivalCode from "./Components/Auth/RetrivalCode";
import PDFViewer from "./Components/Viewer/PDFViewer";
import WalletCard from "./Components/Viewer/WalletCard";
import Reupload from "./Components/Reupload/Reupload";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";

function App() {
  return (
    <div className="main">
      <Header />
      <Router>
        <Route path="/" exact component={MainForm} />
        <Route path="/login" exact component={Login} />
        <Route path="/login-otp" exact component={LoginOTP} />
        <Route path="/retrievalcode" exact component={RetrivalCode} />
        <Route path="/pdf" exact component={PDFViewer} />
        <Route path="/reupload" exact component={Reupload} />
        <Route path="/wallet-card" exact component={WalletCard} />
        <Route path="/missing-dose" exact component={MissingDose} />
      </Router>

      <Footer />
    </div>
  );
}

export default App;
