import React, { useEffect } from "react";
import { Grid, InputLabel } from "@mui/material";
import "./Retrival.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MenuItem } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as API from "../../Service/API/index";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router";

export default function RetriveMode() {
  const history = useHistory();

  const [month] = React.useState([
    { id: "01", month: "January" },
    { id: "02", month: "February" },
    { id: "03", month: "March" },
    { id: "04", month: "April" },
    { id: "05", month: "May" },
    { id: "06", month: "June" },
    { id: "07", month: "July" },
    { id: "08", month: "August" },
    { id: "09", month: "September" },
    { id: "10", month: "October" },
    { id: "11", month: "November" },
    { id: "12", month: "December" },
  ]);
  const [year, SetYear] = React.useState([]);
  const [date, SetDate] = React.useState([]);

  useEffect(() => {
    let year_array = [];
    for (var i = moment(new Date()).format("yyyy"); i - 1909; i--) {
      year_array.push(i);
    }
    SetYear(year_array);

    let date_array = [];
    for (var j = 1; j <= 31; j++) {
      let number = j < 10 ? "0" + j : j;
      date_array.push(number);
    }
    SetDate(date_array);
  }, []);

  const [dobdate, setdobdate] = React.useState("");
  const [dobmonth, setdobmonth] = React.useState("");
  const [dobyear, setdobyear] = React.useState("");
  const [codeVal, setcodeVal] = React.useState("");
  const [loader, setloader] = React.useState(false);

  const [dobdateError, setdobdateError] = React.useState(false);
  const [dobmonthError, setdobmonthError] = React.useState(false);
  const [dobyearError, setdobyearError] = React.useState(false);

  const [retrivalerror, setretrivalerror] = React.useState(false);
  const [retrivalLengtherror, setretrivalLengtherror] = React.useState(false);
  const [dateerror, setdateerror] = React.useState(false);
  const [errorMessage, SeterrorMessage] = React.useState(false);

  const handleChange = (event) => {
    if (event.target.value.length <= 19) {
      setcodeVal(event.target.value.toUpperCase());
    }
  };

  const keyDown = (e) => {
    if (e.keyCode != 8) {
      if (codeVal.length < 19) {
        if (e.key.match("[A-Za-z0-9]") != null) {
          if (
            codeVal.length === 4 ||
            codeVal.length === 9 ||
            codeVal.length === 14
          ) {
            // console.log(e, codeVal);
            let val = codeVal + "-";
            setcodeVal(val);
          }
        }
      }
    }
  };

  const formSubmit = (e) => {
    // setloader(true);
    if (codeVal === null || codeVal === "") {
      setretrivalerror(true);
      return 0;
    } else if (codeVal.length < 19) {
      setretrivalerror(false);
      setretrivalLengtherror(true);
      return 0;
    } else if (dobmonth == "") {
      setdobmonthError(true);
      setdobdateError(false);
      setretrivalerror(false);
      setretrivalLengtherror(false);
      return 0;
    } else if (dobdate == "") {
      setdobdateError(true);
      setretrivalerror(false);
      setretrivalLengtherror(false);
      return 0;
    } else if (dobyear == "") {
      setdobyearError(true);
      setdobmonthError(false);
      setdobdateError(false);
      setretrivalerror(false);
      setretrivalLengtherror(false);
      return 0;
    } else {
      setdobyearError(false);
      setdobmonthError(false);
      setdobdateError(false);
      setretrivalerror(false);
      setretrivalLengtherror(false);
      //
      setloader(true);
      let data = {
        retrievalCode: codeVal,
        dob: dobyear + "-" + dobmonth + "-" + dobdate,
      };
      API.GetOTP_API(data, (res) => {
        if (res.statusCode == "200") {
          setloader(true);
          console.log(res);
          localStorage.setItem("patientId", res.info.patientId);
          history.push({
            pathname: "/login",
            state: res.info,
          });
        } else {
          setloader(false);
          SeterrorMessage(true);
        }
      });
    }
  };
  const EnterKeynext = (evt) => {
    if (evt.keyCode == 13) {
      formSubmit();
    }
  };

  return (
    <>
      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="retrival_container">
            <Grid container spacing={8}>
              <Grid item xs={12} md={12} sm={12}>
                <div className="my" style={{ textAlign: "center" }}>
                  {" "}
                  <p>
                    Please enter your{" "}
                    <span style={{ fontWeight: "600" }}>
                      retrieval code and date of birth
                    </span>
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <InputLabel className="mb">Retrieval Code *</InputLabel>
                <TextField
                  fullWidth
                  style={{ marginTop: "20px" }}
                  className="form_textfield "
                  placeholder="####-####-####-####"
                  value={codeVal}
                  onChange={handleChange}
                  onKeyDown={keyDown}
                  autoComplete="off"
                  type="text"
                  inputProps={{ maxLength: 19 }}
                  onKeyUp={EnterKeynext}
                />
                {retrivalerror ? (
                  <div className="errorText">Please enter Retrieval code </div>
                ) : retrivalLengtherror ? (
                  <div className="errorText">
                    Please enter 16 digit Retrieval code
                  </div>
                ) : (
                  <div></div>
                )}
                <div
                  style={{
                    paddingTop: "15px",
                    color: "#BDC3C7",
                    fontSize: "12px",
                    fontFamily: "Poppins, sans-serif ",
                  }}
                >
                  16 digits retrieval code sent as a text message
                </div>
              </Grid>

              <Grid item xs={12} md={6} sm={12}>
                <InputLabel className="mb">Date Of Birth *</InputLabel>
                <FormControl
                  className="form-dropdown retriva_dob_dropdown"
                  variant="standard"
                >
                  <InputLabel>Month</InputLabel>
                  <Select
                    name="dobmonth"
                    label="Month"
                    value={dobmonth}
                    onChange={(e) => setdobmonth(e.target.value)}
                  >
                    {month.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  className="form-dropdown retriva_dob_dropdown"
                  variant="standard"
                >
                  <InputLabel>Day</InputLabel>
                  <Select
                    label="Day"
                    name="dobdate"
                    value={date.length ? dobdate : ""}
                    onChange={(e) => setdobdate(e.target.value)}
                  >
                    {date.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  className="form-dropdown retriva_dob_dropdown"
                  variant="standard"
                >
                  <InputLabel>Year</InputLabel>
                  <Select
                    name="dobyear"
                    label="Year"
                    value={year.length ? dobyear : ""}
                    onChange={(e) => setdobyear(e.target.value)}
                  >
                    {year.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {dobdateError ? (
                  <div className="errorText">Please select day </div>
                ) : dobmonthError ? (
                  <div className="errorText">Please select month </div>
                ) : dobyearError ? (
                  <div className="errorText">Please select year </div>
                ) : (
                  <div></div>
                )}
              </Grid>
            </Grid>
          </div>
          <div className="retrival_btn_box mb">
            <button className="btn" onClick={formSubmit}>
              Next
            </button>
          </div>
          {errorMessage ? (
            <div className="errorText" style={{ textAlign: "center" }}>
              No patient record found
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
