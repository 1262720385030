import React, { useEffect } from "react";
import {
    Grid,
    Typography,
    InputLabel,
    FormControlLabel,
    Checkbox,
    TextField,
    FormControl,
    Select,
} from "@mui/material";
import moment from "moment";
import { MenuItem } from "@material-ui/core";
import * as API from "../../Service/API/index.js";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { ToastContainer, toast } from "react-toastify";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router";

const MissingDose = () => {
    const history = useHistory();

    useEffect(() => {
        console.log("{localStorage.getItem('pdf')", localStorage.getItem("pdf"));
        if (localStorage.getItem("pdf") === null) {
            history.push({
                pathname: "/retrievalcode",
            });
        }
    }, []);

    const [showDiv1, setshowDiv1] = React.useState(true);
    const [showDiv2, setshowDiv2] = React.useState(false);

    //======Vaccine Card Image State======//
    const [vaccineFile, setvaccineFile] = React.useState();
    const [vaccineFilesize, setvaccineFilesize] = React.useState(false);
    const [vaccineTypeError, setvaccineTypeError] = React.useState(false);
    const [VaccineimgShow, setVaccineimgshow] = React.useState("");
    const [vaccineError, setvaccineError] = React.useState(false);

    //======Error States======//
    const [vaccinationError, setvaccinationError] = React.useState(false);
    const [vaccinationdateError, setvaccinationdateError] = React.useState(false);
    const [lotError, setlotError] = React.useState(false);
    const [countryError, setcountryError] = React.useState(false);
    const [stateError, setstateError] = React.useState(false);
    const [countyError, setcountyError] = React.useState(false);
    const [cityError, setcityError] = React.useState(false);
    const [locationError, setlocationError] = React.useState(false);
    const [OtherlocationError, setOtherlocationError] = React.useState(false);

    //======Second Stepper States======//
    const [doseList, setdoseList] = React.useState([
        {
            vaccineType: "",
            vaccineDate: null,
            first_notsure: false,
            lotNumber: "",
            county: "",
            city: "",
            country: "",
            state: "",
            labId: 32,
            location: "Other",
            otherLabName: "",
            stateList: [],
            countyList: [],
        },
    ]);

    //======API values store States======//
    const [VaccineTypes, setVaccineTypes] = React.useState([]);
    const [stateOrg, setstateOrg] = React.useState([]);
    const [countryList, setCountryList] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [stateLoader, setstateLoader] = React.useState(false);
    const [countyLoader, setcountyLoader] = React.useState(false);
    const [countyLoaderIndex, setcountyLoaderIndex] = React.useState("");
    const [stateLoaderIndex, setstateLoaderIndex] = React.useState("");
    //======Get Lab======//
    const GetVaccinationConfigs = () => {
        API.GetOrg_API((res) => {
            setstateOrg(res.info);
        });
    };

    //======Get Vaccine Types======//
    const GetVaccinationNames = () => {
        API.GetVaccination_API((res) => {
            setVaccineTypes(res.info);
        });
    };

    //======Get CountryList======//
    const GetCountryList = () => {
        API.GET_BVI_COUNTRYLIST((res) => {
            setCountryList(res.info);
        });
    };

    useEffect(() => {
        GetVaccinationNames();
        GetCountryList();
        GetVaccinationConfigs();
    }, []);


    const VaccineHandler = (event) => {
        setvaccineTypeError(false);
        setvaccineError(false);
        const fileTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
            "image/heif",
        ];
        if (
            event.target.files.length != 0 &&
            fileTypes.includes(event.target.files[0].type)
        ) {
            if (
                event.target.files.length != 0 &&
                (event.target.files[0].type === "image/jpeg" ||
                    event.target.files[0].type === "image/png" ||
                    event.target.files[0].type === "image/jpg")
            ) {
                setvaccineFile(event.target.files);
                setVaccineimgshow(
                    event.target.files.length != 0
                        ? URL.createObjectURL(event.target.files[0])
                        : ""
                );
                setvaccineError(false);
            } else if (
                event.target.files.length != 0 &&
                (event.target.files[0].type === "application/pdf" ||
                    event.target.files[0].type === "image/heif")
            ) {
                setvaccineFile(event.target.files);
                setVaccineimgshow("");
                setvaccineError(false);
            }
        } else {
            setVaccineimgshow("");
            setvaccineFile();
            setvaccineTypeError(true);
            setvaccineError(false);
        }

        const fileSize =
            event.target.files.length != 0
                ? event.target.files[0].size / 1024 / 1024
                : "";
        if (event.target.files.length != 0 && fileSize > 4) {
            setvaccineFilesize(true);
        } else {
            setvaccineFilesize(false);
        }
    };

    const doseOnchange = (e, index, field) => {
        if (field === "vaccineDate") {
            const list = [...doseList];
            list[index]["vaccineDate"] = e === null ? null : moment(e);
            setdoseList(list);
        } else if (field === "vaccineType") {
            const { name, value } = e.target;
            const list = [...doseList];
            list[index][name] = value;
            setdoseList(list);
        } else if (field === "lotNumber") {
            const name = e.target.name;
            const value = e.target.value.replaceAll('"', "'");
            const list = [...doseList];
            list[index][name] = value.replaceAll("''", "'");
            setdoseList(list);
        } else if (field === "country") {
            const { name, value } = e.target;
            const list = [...doseList];
            list[index][name] = value;
            setstateLoaderIndex(`state` + index);
            setdoseList(list);
            setstateLoader(true);
            countryList.map((item) => {
                if (item.countryCode == value) {
                    let data = {
                        countryId: item.id,
                    };
                    API.GET_BVI_STATELIST(data, (res) => {
                        if (res.statusCode === 200) {
                            list[index]["state"] = "";
                            let allEntries = Object.entries(res.info.states);
                            let sortedEntries = allEntries.sort((a, b) =>
                                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
                            );
                            list[index]["stateList"] = sortedEntries;
                            setstateLoader(false);
                        }
                    });
                }
            });
        } else if (field === "state") {
            const { name, value } = e.target;
            const list = [...doseList];
            list[index][name] = value;
            setcountyLoaderIndex(`county` + index);
            setdoseList(list);
            setcountyLoader(true);
            let check1 = e.target.value.split(",");
            list[index]["stateList"].map((item) => {
                if (item[1].stateCode == check1[0]) {
                    let data = {
                        stateId: item[1].id,
                    };

                    API.GET_BVI_CITYLIST(data, (res) => {
                        if (res.statusCode === 200) {
                            list[index]["county"] = "";
                            list[index]["city"] = "";
                            let allEntries = Object.entries(res.info.cities);
                            let sortedEntries = allEntries.sort((a, b) =>
                                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
                            );
                            list[index]["countyList"] = sortedEntries;
                            setcountyLoader(false);
                        }

                    });
                }
            });
        } else if (field === "county") {
            const { name, value } = e.target;
            const list = [...doseList];
            list[index][name] = value;
            setdoseList(list);
        } else if (field === "city") {
            const name = e.target.name;
            const value = e.target.value.replaceAll('"', "'");
            const list = [...doseList];
            list[index][name] = value.replaceAll("''", "'");
            setdoseList(list);
        } else if (field === "location") {
            const { name, value } = e.target;
            let labidvalue = name.split("?")[0];
            let labname = name.split("?")[1];
            const lablocation = stateOrg.filter((item) => {
                return item.labId == value;
            });
            const list = [...doseList];
            list[index][labidvalue] = value;
            list[index][labname] = lablocation[0].organizationName;
            setdoseList(list);
        } else if (
            field === "first_notsure" &&
            (e.target.checked === false || e.target.checked === true)
        ) {
            const { name, checked } = e.target;
            const list = [...doseList];
            list[index][name] = checked;
            setdoseList(list);
        } else if (field === "otherLabName") {
            const name = e.target.name;
            const value = e.target.value.replaceAll('"', "'");
            const list = [...doseList];
            list[index][name] = value.replaceAll("''", "'");
            setdoseList(list);
        }
    };

    const handleRemoveClick = (index) => {
        const list = [...doseList];
        list.splice(index, 1);
        setdoseList(list);
    };

    const AddDose = (e) => {
        e.preventDefault();
        setvaccinationError(false);
        setvaccinationdateError(false);
        setlotError(false);
        setcountryError(false);
        setstateError(false);
        setcountyError(false);
        setcityError(false);
        setlocationError(false);
        setOtherlocationError(false);
        if (
            vaccineFile === undefined ||
            vaccineFile === "" ||
            vaccineFile.length == 0
        ) {
            setvaccineError(true);
            setvaccineTypeError(false);
        } else if (vaccineFilesize == true) {
            return 0;
        } else {
            let arr = doseList.map((item) => {
                return {
                    vaccineType: item.vaccineType,
                    vaccineDate: moment(item.vaccineDate).format("yyyy-MM-DD"),
                    lotNumber:
                        item.first_notsure === true
                            ? "I'm not sure"
                            : item.lotNumber.trim(),
                    country: item.country,
                    state: item.state,
                    county: item.county,
                    city: item.city,
                    countyList: item.countyList,
                    labId: item.labId,
                    otherLabName: item.labId === 32 ? item.otherLabName.trim() : "",
                };
            });

            let filter_data = arr.filter(function (x) {
                if (x.vaccineType != "") {
                    setvaccinationError(false);
                    return true;
                } else if (x.vaccineDate != "Invalid date" || x.vaccineDate != null) {
                    setvaccinationdateError(false);
                    return true;
                } else if (x.lotNumber.trim() != "") {
                    setlotError(false);
                    return true;
                } else if (x.country != "") {
                    setcountryError(false);
                    return true;
                } else if (x.state != "") {
                    return true;
                } else if (x.countyList.length && x.county != "") {
                    return true;
                } else if (!x.countyList.length && x.city != "") {
                    return true;
                } else if (x.labId != "") {
                    setlocationError(false);
                    return true;
                } else if (x.labId !== 32 && x.otherLabName.trim() !== "") {
                    setOtherlocationError(false);
                    return true;
                }
            });

            let Checkfilter_data = arr.filter(function (x) {
                if (x.vaccineType == "") {
                    setvaccinationError(true);
                    return true;
                } else if (x.vaccineDate === "Invalid date" || x.vaccineDate === null) {
                    setvaccinationdateError(true);
                    return true;
                } else if (x.lotNumber.trim() == "") {
                    setlotError(true);
                    return true;
                } else if (x.country == "") {
                    setcountryError(true);
                    return true;
                } else if (x.state == "") {
                    setstateError(true);
                    return true;
                } else if (x.countyList.length && x.county == "") {
                    setcountyError(true);
                    return true;
                } else if (!x.countyList.length && x.city == "") {
                    setcityError(true);
                    return true;
                } else if (x.labId == "") {
                    setlocationError(true);
                    return true;
                } else if (x.labId === 32 && x.otherLabName.trim() === "") {
                    setOtherlocationError(true);
                    return true;
                }
            });

            if (Checkfilter_data.length === 0) {
                setvaccinationError(false);
                setvaccinationdateError(false);
                setlotError(false);
                setcountryError(false);
                setstateError(false);
                setcountyError(false);
                setcityError(false);
                setlocationError(false);
                setOtherlocationError(false);
                setdoseList([
                    ...doseList,
                    {
                        vaccineType: "",
                        vaccineDate: null,
                        first_notsure: false,
                        lotNumber: "",
                        county: "",
                        city: "",
                        country: "",
                        state: "",
                        labId: 32,
                        location: "Other",
                        otherLabName: "",
                        stateList: [],
                        countyList: [],
                    },
                ]);
            }
        }
    };


    const next2 = (e) => {
        setvaccinationError(false);
        setvaccinationdateError(false);
        setlotError(false);
        setcountryError(false);
        setstateError(false);
        setcountyError(false);
        setcityError(false);
        setlocationError(false);
        setOtherlocationError(false);

        if (
            vaccineFile === undefined ||
            vaccineFile === "" ||
            vaccineFile.length == 0
        ) {
            setvaccineError(true);
            setvaccineTypeError(false);
        } else if (vaccineFilesize == true) {
            return 0;
        } else {
            let arr = doseList.map((item) => {
                return {
                    vaccineType: item.vaccineType,
                    vaccineDate:
                        item.vaccineDate == null
                            ? null
                            : moment(item.vaccineDate).format("yyyy-MM-DD"),
                    lotNumber:
                        item.first_notsure === true
                            ? "I'm not sure"
                            : item.lotNumber.trim(),
                    country: item.country,
                    location: item.state,
                    county: item.county,
                    city: item.city,
                    countyList: item.countyList,
                    clinicName: item.location,
                    labId: item.labId,
                    otherLabName: item.labId === 32 ? item.otherLabName.trim() : "",
                };
            });

            let filter_data = arr.filter(function (x) {
                if (x.vaccineType != "") {
                    setvaccinationError(false);
                    return true;
                } else if (x.vaccineDate != "Invalid date" || x.vaccineDate != null) {
                    setvaccinationdateError(false);
                    return true;
                } else if (x.lotNumber.trim() != "") {
                    setlotError(false);
                    return true;
                } else if (x.country != "") {
                    setcountryError(false);
                    return true;
                } else if (x.location != "") {
                    setstateError(false);
                    return true;
                } else if (x.countyList.length && x.county != "") {
                    setcountyError(false);
                    return true;
                } else if (!x.countyList.length && x.city != "") {
                    setcityError(false);
                    return true;
                } else if (x.labId != "") {
                    setlocationError(false);
                    return true;
                } else if (x.labId !== 32 && x.otherLabName.trim() !== "") {
                    setOtherlocationError(false);
                    return true;
                }
            });
            const newFilterArr = filter_data.map(({ countyList, ...rest }) => {
                return rest;
            });

            let Checkfilter_data = arr.filter(function (x) {
                if (x.vaccineType == "") {
                    setvaccinationError(true);
                    return true;
                } else if (x.vaccineDate === "Invalid date" || x.vaccineDate === null) {
                    setvaccinationdateError(true);
                    return true;
                } else if (x.lotNumber.trim() == "") {
                    setlotError(true);
                    return true;
                } else if (x.country == "") {
                    setcountryError(true);
                    return true;
                } else if (x.location == "") {
                    setstateError(true);
                    return true;
                } else if (x.countyList.length && x.county == "") {
                    setcountyError(true);
                    return true;
                } else if (!x.countyList.length && x.city == "") {
                    setcityError(true);
                    return true;
                } else if (x.labId == "") {
                    setlocationError(true);
                    return true;
                } else if (x.labId === 32 && x.otherLabName.trim() === "") {
                    setOtherlocationError(true);
                    return true;
                }
            });

            if (doseList.length) {
                for (let i = 0; i < doseList.length; i++) {
                    if (doseList[i].vaccineType === "") {
                        setvaccinationError(true);
                    } else if (
                        doseList[i].vaccineDate === "Invalid date" ||
                        doseList[i].vaccineDate === null
                    ) {
                        setvaccinationdateError(true);
                    } else if (
                        doseList[i].first_notsure === false &&
                        doseList[i].lotNumber.trim() === ""
                    ) {
                        setlotError(true);
                    } else if (doseList[i].country === "") {
                        setcountryError(true);
                    } else if (doseList[i].state === "") {
                        setstateError(true);
                    } else if (doseList[i].countyList.length && doseList[i].county === "") {
                        setcountyError(true);
                    } else if (!doseList[i].countyList.length && doseList[i].city === "") {
                        setcityError(true);
                    } else if (doseList[i].labId === "") {
                        setlocationError(true);
                    } else if (
                        doseList[i].labId === 32 &&
                        doseList[i].otherLabName.trim() === ""
                    ) {
                        setOtherlocationError(true);
                    }
                }

                if (Checkfilter_data.length === 0) {
                    console.log(">>>>>>> API call >>>>>>>>>>");
                    setLoader(true);
                    const formData = new FormData();
                    let data = {
                        patientID: parseInt(localStorage.getItem("patientId")),
                        vaccinationInfos: newFilterArr,
                    };
                    console.log("data", data)
                    formData.append("formData", JSON.stringify(data));
                    formData.append("cardFile", vaccineFile[0]);



                    API.MISSING_DOSE_API(formData, (res) => {
                        if (res.statusCode === 200) {
                            console.log("success", res);
                            setLoader(false);
                            setshowDiv1(false);
                            setshowDiv2(true);
                            window.scrollTo(0, 0);

                            setdoseList([
                                ...doseList,
                                {
                                    vaccineType: "",
                                    vaccineDate: null,
                                    first_notsure: false,
                                    lotNumber: "",
                                    state: "BVI,British Virgin Islands",
                                    county: "",
                                    city: "",
                                    country: "",
                                    labId: 32,
                                    location: "Other",
                                    otherLabName: "",
                                    stateList: [],
                                    countyList: [],
                                },
                            ]);
                            setvaccineFile("");

                        } else {
                            setLoader(false);
                        }
                    });

                    //===================//
                }
            }
        }
    };
    const EnterKeynext = (evt) => {
        if (evt.keyCode == 13) {
            next2();
        }
    };

    const Backbtn = () => {
        history.push({
            pathname: "/pdf",
        });
    };


    return (
        <>
            <ToastContainer />

            {loader == true ? (
                <div className="loaderAdj">
                    <CircularProgress />
                </div>
            ) : showDiv1 === true ? (
                <div className="form_body">
                    <Grid container className="mt">
                        <Grid item xs={12} md={12} sm={12}>
                            <Typography className="mb" variant="h6">
                                Report a missing dose from your record
                            </Typography>
                            <div className="form_back">
                                <div className="form_overlay">
                                    <Typography className="pt mx" variant="subtitle1">
                                    </Typography>
                                    <Grid container spacing={3} className="px py">
                                        <Grid item xs={12} md={12} sm={12} className="gray-text">
                                            To confirm your vaccination status, please upload a picture of your most recent vaccination record or your SMART Health Card.
                                        </Grid>

                                        <Grid item xs={12} md={6} sm={12}>
                                            <div className="vaccination-card">
                                                {vaccineFile ? (
                                                    <div>
                                                        Click 'Next' to continue or upload a different image
                                                        <br />
                                                        <span style={{ fontSize: "14px" }}>
                                                            Please make sure your name and date of birth are
                                                            visible in the image
                                                        </span>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="d-inline-flex">
                                                <label
                                                    htmlFor="file-upload1"
                                                    className="custom-file-upload"
                                                    style={{ width: "320px" }}
                                                >
                                                    {vaccineFile ? (
                                                        vaccineFile.length > 0 ? (
                                                            vaccineFile[0].name
                                                        ) : (
                                                            <span className="d-flex">
                                                                <InsertPhotoIcon
                                                                    style={{ width: "20px", marginRight: "5px" }}
                                                                />
                                                                Upload updated vaccination record
                                                            </span>
                                                        )
                                                    ) : (
                                                        <span style={{ width: "100%" }} className="d-flex">
                                                            <InsertPhotoIcon
                                                                style={{ width: "20px", marginRight: "5px" }}
                                                            />
                                                            Upload updated vaccination record
                                                        </span>
                                                    )}
                                                </label>
                                                <span className="img-label">
                                                    Supports png, jpeg, jpg, pdf, heif
                                                </span>
                                                <input
                                                    id="file-upload1"
                                                    accept=".png, .jpeg, .jpg, .pdf , .heif"
                                                    data-max-size="32154"
                                                    type="file"
                                                    name="licenseFile"
                                                    onChange={VaccineHandler}
                                                />
                                            </div>
                                            {vaccineError ? (
                                                <div className="errorText">
                                                    Please upload vaccine record
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                            {vaccineFilesize ? (
                                                <div className="errorText">
                                                    File size should not exceed more than 4 mb
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                            {vaccineTypeError ? (
                                                <div className="errorText">
                                                    Please upload image with required type
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={6} sm={12}>
                                            {vaccineFile &&
                                                vaccineFile.length > 0 &&
                                                vaccineFile[0].type != "application/pdf" &&
                                                vaccineFile[0].type != "image/heif" &&
                                                vaccineFile[0].type !== "" ? (
                                                <div className="preview-img">
                                                    <img
                                                        alt="VaccinationPreviewImg"
                                                        src={
                                                            vaccineFile
                                                                ? vaccineFile.length > 0
                                                                    ? VaccineimgShow
                                                                    : ""
                                                                : ""
                                                        }
                                                    ></img>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </Grid>
                                    </Grid>
                                    {doseList.map((dose, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <Grid
                                                    container
                                                    spacing={3}
                                                    style={{ paddingTop: "10px" }}
                                                    className="px"
                                                >
                                                    <Grid item xs={12} md={12} sm={12}>
                                                        <FormControl
                                                            className="form-dropdown form-vaccine-type"
                                                            fullWidth
                                                            variant="standard"
                                                        >
                                                            <InputLabel>Vaccine name *</InputLabel>
                                                            <Select
                                                                name="vaccineType"
                                                                value={dose.vaccineType}
                                                                onChange={(e) =>
                                                                    doseOnchange(e, i, "vaccineType")
                                                                }
                                                                required
                                                            >
                                                                {VaccineTypes.map((item) => (
                                                                    <MenuItem value={item.vaccineName}>
                                                                        {item.vaccineName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        {vaccinationError === true &&
                                                            dose.vaccineType === "" ? (
                                                            <div className="errorText">
                                                                Please select Vaccine name
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={12} md={6} sm={12}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                style={{ width: "100%" }}
                                                                clearable
                                                                value={dose.vaccineDate}
                                                                className="dateSelection"
                                                                onChange={(e) =>
                                                                    doseOnchange(e, i, "vaccineDate")
                                                                }
                                                                format="MM/dd/yyyy"
                                                                label={`Vaccination date (MM/DD/YYYY)`}
                                                                maxDate={new Date()}
                                                                required
                                                                autoOk
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                        {vaccinationdateError === true &&
                                                            dose.vaccineDate === null ? (
                                                            <div className="errorText">
                                                                Please enter the Vaccine date
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={3} sm={12}>
                                                        <TextField
                                                            className="form_textfield mt"
                                                            variant="standard"
                                                            placeholder="Lot # *"
                                                            autoComplete="off"
                                                            disabled={dose.first_notsure == true ? true : false}
                                                            value={
                                                                dose.first_notsure === false
                                                                    ? dose.lotNumber
                                                                    : dose.first_notsure === true
                                                                        ? "I'm not sure"
                                                                        : dose.lotNumber
                                                            }
                                                            onChange={(e) => doseOnchange(e, i, "lotNumber")}
                                                            onKeyUp={EnterKeynext}
                                                            required
                                                            name="lotNumber"
                                                            inputProps={{ maxLength: 40 }}
                                                        />
                                                        {lotError === true &&
                                                            dose.lotNumber.trim() === "" &&
                                                            dose.first_notsure === false ? (
                                                            <div className="errorText">
                                                                Please enter LOT number
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={3} sm={12}>
                                                        <FormControlLabel
                                                            className="form-not-sure mt "
                                                            control={
                                                                <Checkbox
                                                                    style={{ paddingRight: "10px" }}
                                                                    onChange={(e) =>
                                                                        doseOnchange(e, i, "first_notsure")
                                                                    }
                                                                    name="first_notsure"
                                                                    checked={dose.first_notsure}
                                                                />
                                                            }
                                                            label=" I'm not sure"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} sm={12}>
                                                        <FormControl
                                                            className="form-dropdown"
                                                            fullWidth
                                                            variant="standard"
                                                        >
                                                            <InputLabel>Country*</InputLabel>
                                                            <Select
                                                                name="country"
                                                                value={dose.country}
                                                                onChange={(e) => doseOnchange(e, i, "country")}
                                                                required
                                                            >
                                                                {countryList.map((item) => (
                                                                    <MenuItem value={item.countryCode}>
                                                                        {item.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        {countryError === true && dose.country === "" ? (
                                                            <div className="errorText">
                                                                Please select the Country
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </Grid>
                                                    {`state` + i == stateLoaderIndex && stateLoader == true ? (
                                                        <div className="stateLoader">
                                                            <div class="loader1">
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <Grid item xs={12} md={6} sm={12}>
                                                            <FormControl
                                                                className="form-dropdown"
                                                                fullWidth
                                                                variant="standard"
                                                            >
                                                                <InputLabel>State/Territory*</InputLabel>
                                                                <Select
                                                                    name="state"
                                                                    value={dose.state}
                                                                    onChange={(e) => doseOnchange(e, i, "state")}
                                                                    required
                                                                >
                                                                    {dose.stateList.map((item) => (
                                                                        <MenuItem
                                                                            value={item[1].stateCode}
                                                                        >
                                                                            {item[0]}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            {stateError === true && dose.state === "" ? (
                                                                <div className="errorText">
                                                                    Please select the State
                                                                </div>
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </Grid>
                                                    )}

                                                    {`county` + i == countyLoaderIndex && countyLoader == true ? (
                                                        <div className="stateLoader">
                                                            <div class="loader1">
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </div>
                                                        </div>

                                                    ) : (<>
                                                        {dose.countyList.length ? <><Grid item xs={12} md={6} sm={12}>
                                                            <FormControl
                                                                className="form-dropdown"
                                                                fullWidth
                                                                variant="standard"
                                                            >
                                                                <InputLabel>County/District*</InputLabel>
                                                                <Select
                                                                    name="county"
                                                                    value={dose.county}
                                                                    onChange={(e) => doseOnchange(e, i, "county")}
                                                                    required
                                                                >
                                                                    {dose.countyList.map((item) => (
                                                                        <MenuItem value={item[0]}>{item[0]}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            {countyError === true && dose.county === "" ? (
                                                                <div className="errorText">
                                                                    Please select the County
                                                                </div>
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </Grid></> : <Grid item xs={12} md={6} sm={12}>
                                                            <TextField
                                                                className="form_textfield"
                                                                label="City/Estate*"
                                                                variant="standard"
                                                                name="city"
                                                                inputProps={{ maxLength: 40, autoComplete: "none" }}
                                                                value={dose.city}
                                                                onChange={(e) => doseOnchange(e, i, "city")}
                                                            />
                                                            {cityError === true && dose.city === "" ? (
                                                                <div className="errorText">
                                                                    Please enter the City
                                                                </div>
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </Grid>}</>
                                                    )
                                                    }

                                                    {/* <Grid item xs={12} md={6} sm={12}>
                          <FormControl
                            className="form-dropdown"
                            fullWidth
                            variant="standard"
                          >
                            <InputLabel
                              style={{
                                paddingBottom: "0.3em",
                                width: "100%",
                              }}
                            >
                              Dose location *
                            </InputLabel>
                            <Select
                              required
                              name="labId?location"
                              value={dose.labId}
                              onChange={(e) => doseOnchange(e, i, "location")}
                            >
                              {stateOrg.map((item) => (
                                <MenuItem value={item.labId}>
                                  {item.organizationName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {locationError === true && dose.location === "" ? (
                            <div className="errorText">
                              Please select dose location
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </Grid> */}
                                                    {dose.labId === 32 && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={6}
                                                            sm={12}
                                                            style={{ marginTop: "4px" }}
                                                        >
                                                            <TextField
                                                                value={dose.otherLabName}
                                                                variant="standard"
                                                                className="form_textfield"
                                                                name="otherLabName"
                                                                label="Please specify vaccination site *"
                                                                onKeyUp={EnterKeynext}
                                                                onChange={(e) =>
                                                                    doseOnchange(e, i, "otherLabName")
                                                                }
                                                                inputProps={{
                                                                    maxLength: 30,
                                                                    autoComplete: "off",
                                                                }}
                                                                autoComplete="off"
                                                            />

                                                            {OtherlocationError === true &&
                                                                dose.otherLabName.trim() === "" ? (
                                                                <div className="errorText">
                                                                    Please enter the site name
                                                                </div>
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={12} md={12} sm={12}>
                                                        <div className="removeDose">
                                                            {i ==
                                                                (doseList.length === 1
                                                                    ? doseList.length
                                                                    : doseList.length - 1) && (
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: "#f07167",
                                                                            padding: "3px 8px",
                                                                            borderRadius: "0.4rem",
                                                                            cursor: "pointer",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                        }}
                                                                        onClick={() => handleRemoveClick(i)}
                                                                    >
                                                                        <DeleteIcon
                                                                            style={{ width: "20px", marginRight: "5px" }}
                                                                        />{" "}
                                                                        Remove
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        );
                                    })}
                                    <Grid container spacing={3} className="px py">
                                        <Grid item xs={12} md={12} sm={12}>
                                            <div className="d-flex addDoseBtn">
                                                <div>
                                                    <button style={{ width: "180px" }} className="btn mt"
                                                        onClick={AddDose}
                                                    >
                                                        <AddBoxIcon
                                                            style={{ width: "20px", marginRight: "5px" }}
                                                        />
                                                        + Another Dose
                                                    </button>
                                                </div>
                                                <div>
                                                    <button style={{ lineHeight: "2.0" }} className="btn1 mt" onClick={next2}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>) : showDiv2 ? (
                    <div className="form_body">
                        <Grid container className="mt">
                            <Grid item xs={12} md={12} sm={12}>
                                <Typography className="mb" variant="h6">
                                    Report a missing dose from your record
                                </Typography>
                                <div className="form_back">
                                    <div className="form_overlay">
                                        <div className="final_success_card">
                                            <div className="success_message">Thank you! </div>
                                            <div>Your Information has been received. </div>
                                        </div>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sm={12}
                                            className="d-flex mt"
                                        >
                                            <button
                                                className="btn"
                                                onClick={Backbtn}
                                            >
                                                Back
                                            </button>

                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                ) : null}
        </>
    )
}

export default MissingDose
