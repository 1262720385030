// import * as ActionConstants from "../Constant/index.js";
import * as api from "../Service/API/index";

export function ReloadNew_Action(data, res) {
  api.ReuploadNew_API(data, (response) => {
    return res(response);
  });
}

export function ReloadExist_Action(data, res) {
  api.ReuploadExist_API(data, (response) => {
    return res(response);
  });
}

export function ReloadMissingDose_Action(data, res) {
  api.ReuploadMissingDose_API(data, (response) => {
    return res(response);
  });
}
